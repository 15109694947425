import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Index from "./components/home";
import { green, orange } from "@mui/material/colors";
import Polygons from "./components/home/Particles";
import ParticleSvgComponent from "./components/home/ParticleSvgComponent";
import MyPage from "./components/home/MyPage";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: orange[500],
    },
  },
  typography: {
    fontFamily: ["Courier Prime", "normal"].join(","),
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path="/">
            {/* <ParticleSvgComponent /> */}
            <Index />
          </Route>
          <Route exact path="/projects">
            {/* <ParticleSvgComponent /> */}
            <MyPage />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}
