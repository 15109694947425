import React from "react";
import { Container, Grid, Typography } from "@mui/material";

const contentData = [
  {
    timePeriod: "2020-2023",
    backgroundColor: "rgba(245, 39, 50, 0.58)",
    position: "Chief Technology Officer",
    company: "Stealth Startup, Sacramento",
    techStack:
      "React, Postgres, Django, Python, Elasticsearch, Github, AWS, Jupyter, Docker, VS Code, and Tensorflow.",
    description: `Designed developed and implemented Hekafy's Data science and web infrastructure. 
    Developed backend infrastructure of health assessment. Developed natural language processing pipeline 
    for nutritional recommendation.`,
    notWork: [
      "It's 2020, the world is just coming out of pandemic. I just got a message on my LinkedIn, someone asking me if I could work with them on a working device partnership. This was exciting, I decided to speak with them. The idea was to build a videogame that can be played by controlling your breath. I spoke with the gentleman from New York, he showed me this device you wear across your chest and game prototype similar flappy bird.",
    ],
  },

  {
    timePeriod: "2019-2020",
    backgroundColor: "rgba(39, 93, 245, 0.58)",
    position: "Chief Technology Officer",
    company: "CivicTechHub",
    techStack:
      "React, Postgres, Django, Python, Elasticsearch, Github, AWS, Jupyter, Docker, VS Code, and Tensorflow.",
    description: `Civitechhub was a non-profit organization established during the pandemic to connect project owners with volunteers. At its peak, it supported over 7,000 projects. As the Chief Technology Officer (CTO), I led the development of the organization's website, including its search functionalities using Elasticsearch. I was responsible for building the user registration system, managing content, and implementing authentication processes, utilizing React for the frontend and Django for the backend. 
   `,
  },
  {
    timePeriod: "2018 - 2019",

    backgroundColor: "rgba(2, 139, 15, 0.5)",
    position: "Health Data Science Fellow",
    company: "Insight Data Science, San Francisco, CA, USA",
    techStack:
      "React, Postgres, Django, Python, Elasticsearch, Github, AWS, Jupyter, Docker, VS Code, and Tensorflow.",
    description:
      "As part of the data science fellowship at Insight, I focused on natural language processing (NLP) challenges. Utilizing a variety of statistical models—including Support Vector Machines (SVM), Random Forest, Boosted Trees, Recurrent Neural Networks (RNN), and BERT—I developed a machine learning pipeline specifically designed to classify doctors' notes from the American Academy of Neurology's AXON database. At the end of 2019, when the coronavirus pandemic began, I collaborated with a team of scientists from around the globe to study the novel sequence features of the virus. Utilizing natural language processing (NLP) and machine learning techniques, I focused on classifying the virus and identifying novel features of the ORF1ab gene.",
    publications: [
      {
        title: "Determining a novel feature-space for SARS-CoV-2 sequence data",
        link: "https://osf.io/preprints/biohackrxiv/xt7gw",
      },
    ],
  },
  {
    timePeriod: "2014-2018",
    backgroundColor: "rgba(80, 201, 0, 0.5)",
    position: "Founder, Full-stack Developer",
    company: "Pyzyme, San Jose, CA, USA",
    techStack: "Django, VS Code, Unity, C#, Objective-C",
    description: `Designed developed andriod and iOS games.`,
  },
  {
    timePeriod: "2009-2013",
    backgroundColor: "rgba(110, 240, 0, 0.5)",
    position: "Post-Doctoral Fellow",
    company: "University of California, Davis, CA, USA",
    description: `
I investigated how cells develop into different types with specific functions, particularly focusing on the formation of reproductive cells and their differentiation. In the process of forming female reproductive cells, called gametophytes, multiple nuclei coexist in the same environment before developing into cells with different functions.

To explore this, I used special plants with altered levels of a hormone called auxin, which affects plant growth and development. I observed the effects of these altered levels using a powerful microscope that can detect fluorescent light, called confocal fluorescent microscopy.

To track the development of these cells, I attached fluorescent markers, which emit green, red, or yellow light, to specific developmental signals. These markers only light up when a nucleus transforms into a cell with a specific function. This helped me determine if the process of cell specialization was occurring correctly.

Additionally, I conducted experiments to understand how cells with half the normal chromosome number, called haploids, are formed.`,
    publications: [
      {
        title:
          "A haploid genetics toolbox for Arabidopsis thaliana | Nature Communications",
        link: "https://pubmed.ncbi.nlm.nih.gov/25358957/",
      },
      {
        title:
          "Auxin Import and Local Auxin Biosynthesis Are Required for Mitotic Divisions, Cell Expansion and Cell Specification during Female Gametophyte Development in Arabidopsis thaliana | PLoS One",
        link: "https://pubmed.ncbi.nlm.nih.gov/25970627/",
      },
    ],
  },
  {
    timePeriod: "2004-2009",
    backgroundColor: "rgba(150, 160, 0, 0.5)",
    position: "CSIR Senior Research Fellow & PhD Candidate",
    company:
      "Center for Cellular and Molecular Biology, Hyderabad, India & Jawaharlal Nehru University, New Delhi, India",
    description: `
I investigated how cells divide to produce reproductive cells with half the chromosome number, a process called meiosis. Specifically, I studied the genetic and molecular interactions involved in a key step of this process called homologous recombination.

To do this, I utilized bioinformatics tools like BLASTP and BLASTN to identify similar genes in budding yeast that are involved in meiosis. I then used T-DNA insertion mutants to observe any abnormalities or defects in the meiotic process caused by these genes.

Next, I isolated and replicated these identified genes and inserted them into chromosomes using a method called GFP tagging. This allowed me to visualize their protein-binding locations within the chromosomes. Using a technique called fluorescent in situ hybridization, I examined the chromosomes for any errors or abnormalities in their pairing during meiosis.`,
    publications: [
      {
        title:
          "AtMND1 is required for homologous pairing during meiosis in Arabidopsis | BMC Molecular Biology",
        link: "https://pubmed.ncbi.nlm.nih.gov/16872528/",
      },
      {
        title:
          "AtCTF7 is required for establishment of sister chromatid cohesion and association of cohesin with chromatin during meiosis in Arabidopsis | BMC Plant Biology",
        link: "https://pubmed.ncbi.nlm.nih.gov/23941555/",
      },
      {
        title:
          "The plant adherin AtSCC2 is required for embryogenesis and sister-chromatid cohesion during meiosis in Arabidopsis | Plant Journal",
        link: "https://pubmed.ncbi.nlm.nih.gov/19228337/",
      },
      {
        title:
          "ACTIN-RELATED PROTEIN6 Regulates Female Meiosis by Modulating Meiotic Gene Expression in Arabidopsis | Plant Cell",
        link: "https://pubmed.ncbi.nlm.nih.gov/24737671/",
      },
    ],
  },
];
// Language: javascript
// Path: frontend/src/components/home/index.jsx
export function Content() {
  return (
    <Grid container id="scroll-container">
      {contentData.map((content, index) => {
        return (
          <Grid
            item
            id="scroll-to-top"
            key={index}
            xs={12}
            style={{
              position: "relative",
              minHeight: "100vh",
              backgroundColor: content.backgroundColor,
              paddingTop: "5vh",
              paddingBottom: "5vh",
              paddingLeft: "5vw",
              paddingRight: "5vw",
            }}
          >
            <React.Fragment>
              <Typography variant="h3">{`${content.timePeriod}`}</Typography>
              <Container maxWidth="xl">
                <Typography variant="h4">{`Position: ${content.position}`}</Typography>
                <Typography variant="h4">{`Company: ${content.company}`}</Typography>
                {content.techStack && (
                  <Typography variant="h4">{`Tech Stack: ${content.techStack}`}</Typography>
                )}
                <Typography variant="h4">{`Description: ${content.description}`}</Typography>
                {content.publications && (
                  <Typography variant="h4">{`Publications:`}</Typography>
                )}
                {content.publications &&
                  content.publications.map((pub, ind) => (
                    <div key={ind}>
                      <Typography variant="h4">
                        {`${ind + 1}) ${pub.title}`}
                        <a href={pub.link} style={{ textDecoration: "none" }}>
                          ⧉
                        </a>
                      </Typography>
                    </div>
                  ))}
              </Container>
            </React.Fragment>
          </Grid>
        );
      })}
    </Grid>
  );
}
