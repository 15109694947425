import React from "react";
import { Grid } from "@mui/material";
import { Home } from "./Home";
import ParticleOnScroll from "./ParticleOnScroll";
import { Rectangle } from "tsparticles";
import ResponsiveAppBar from "./responsiveAppbar";
import { Content } from "./Content";

export default function Index() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <ParticleOnScroll />
      </Grid>
      <Grid item xs={12}>
        <ResponsiveAppBar />
      </Grid>
      <Grid item xs={12} style={{ minHeight: "100vh" }}>
        <Home />
      </Grid>
    </Grid>
  );
}
