import React from "react";
import ParticlesSvg from "./ParticlesSvg";
import ReactSvg from "../../assets/images/particles/React-icon.svg";
import PythonSvg from "../../assets/images/particles/python.svg";
import DjangoSvg from "../../assets/images/particles/django.svg";
import PostgresSvg from "../../assets/images/particles/postgres.svg";
import TesnsorflowSvg from "../../assets/images/particles/tensorflow.svg";

export default function ParticleSvgComponent() {
  const reAct = { position: { x: 0, y: 10 }, icon: ReactSvg, scale: 1 };
  const [svgPolygon, setSvgPolygon] = React.useState(reAct);
  const svgs = [
    reAct,
    { position: { x: 0, y: 0 }, icon: PythonSvg, scale: 4 },
    { position: { x: 30, y: -20 }, icon: DjangoSvg, scale: 3 },
    { position: { x: 50, y: 30 }, icon: PostgresSvg, scale: 1 },
    { position: { x: -10, y: -80 }, icon: TesnsorflowSvg, scale: 3 },
  ];
  React.useEffect(() => {
    const interval = setInterval(() => {
      setSvgPolygon(svgs[Math.floor(Math.random() * svgs.length)]);
      console.log("svg changed");
    }, 7000);
    return () => clearInterval(interval);
  }, []);
  return (
    <React.Fragment>
      <ParticlesSvg svgPolygon={svgPolygon} />
    </React.Fragment>
  );
}
