import React from "react";
import { Helmet } from "react-helmet";
import ParticleSvgComponent from "./ParticleSvgComponent";
import ResponsiveAppBar from "./responsiveAppbar";
import { Grid, Typography } from "@mui/material";
import { Content } from "./Content";

const MyPage = () => {
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      sx={{ position: "relative" }}
    >
      <Grid item xs={12} style={{ minHeight: "100vh" }}>
        <ParticleSvgComponent />
      </Grid>
      <Grid item xs={12}>
        <ResponsiveAppBar />
      </Grid>
      <Grid item xs={12} style={{ minHeight: "10vh" }}></Grid>
      <Grid item xs={12}>
        <Content />
      </Grid>
    </Grid>
  );
};

export default MyPage;
