import React from "react";
import Polygons from "./Particles";

export default function ParticleOnScroll() {
  const [particleConfig, setParticleConfig] = React.useState({
    numParticles: 1,
    linkDistance: 1000,
  });
  const [y, setY] = React.useState(window.scrollY);

  const handleNavigation = React.useCallback(
    (e) => {
      const window = e.currentTarget;
      const isScrollingUp = y > window.scrollY;
      setY(window.scrollY);

      setParticleConfig((prevState) => {
        const updatedConfig = {
          numParticles: prevState.numParticles,
          linkDistance: prevState.linkDistance,
        };

        if (isScrollingUp) {
          updatedConfig.numParticles = Math.max(
            10,
            updatedConfig.numParticles - 2
          ); // Decrease particles
          updatedConfig.linkDistance = Math.max(
            30,
            updatedConfig.linkDistance - 2
          ); // Decrease distance
        } else {
          updatedConfig.numParticles = Math.min(
            30,
            updatedConfig.numParticles + 2
          ); // Increase particles
          updatedConfig.linkDistance = Math.min(
            400,
            updatedConfig.linkDistance + 2
          ); // Increase distance
        }

        return updatedConfig;
      });
    },
    [y]
  );

  React.useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);
  return (
    <React.Fragment>
      <Polygons particleConfig={particleConfig} />
    </React.Fragment>
  );
}
