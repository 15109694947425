import React from "react";
import Particles from "react-tsparticles";
export default function ParticlesSvg({ svgPolygon }) {
  return (
    <Particles
      height="100vh"
      id="polygon-canvas"
      width="100vw"
      options={{
        background: {
          color: "#000000",
          // image: "",
          // position: "50% 50%",
          repeat: "no-repeat",
          size: "cover",
        },
        detectRetina: false,
        fpsLimit: 120,
        interactivity: {
          detectsOn: "canvas",
          events: {
            onHover: {
              enable: true,
              mode: "bubble",
              parallax: {
                enable: false,
                force: 2,
                smooth: 10,
              },
            },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 40,
              duration: 2,
              opacity: 8,
              size: 6,
              speed: 3,
            },
          },
        },
        particles: {
          color: {
            value: ["#4285f4", "#34A853", "#FBBC05", "#EA4335"],
            animation: {
              enable: true,
              speed: 20,
              sync: true,
            },
          },
          lineLinked: {
            blink: false,
            color: "random",
            consent: false,
            distance: 40,
            enable: true,
            opacity: 0.3,
            width: 0.5,
          },
          move: {
            attract: {
              enable: false,
              rotate: {
                x: 600,
                y: 1200,
              },
            },
            bounce: false,
            direction: "none",
            enable: true,
            outMode: "bounce",
            random: true,
            speed: 1.5,
            straight: false,
          },
          number: {
            density: {
              enable: false,
              area: 2000,
            },
            limit: 0,
            value: 500,
          },
          opacity: {
            animation: {
              enable: true,
              minimumValue: 0.05,
              speed: 2,
              sync: false,
            },
            random: false,
            value: 1,
          },
          shape: {
            type: "circle",
          },
          size: {
            animation: {
              enable: false,
              minimumValue: 0.1,
              speed: 40,
              sync: false,
            },
            random: true,
            value: 1,
          },
        },
        polygon: {
          draw: {
            enable: true,
            lineColor: "rgba(97,218,251,0.0)",
            lineWidth: 0.3,
          },
          enable: true,
          move: {
            radius: 1000,
          },
          position: svgPolygon.position,
          inlineArrangement: "equidistant",
          scale: svgPolygon.scale,
          type: "inline",
          url: svgPolygon.icon,
          repeat: "no-repeat",
        },
      }}
    />
  );
}
