import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import Typewriter from "typewriter-effect";
import Container from "@mui/material/Container";

export function Home() {
  const [textColor, setTextColor] = useState("white"); // Initial color
  const [minHeight, setMinHeight] = useState(40); // to have the typing line move up dynamically

  const onCallSetMinHeight = () => {
    setMinHeight((prevHeight) => Math.max(0, prevHeight - 2));
  };

  return (
    <Container maxWidth="xl">
      <Grid
        container
        alignItems="center"
        justify="center"
        sx={{ position: "relative", pointerEvents: "none" }}
      >
        <Grid item xs={12} sx={{ minHeight: `${minHeight}vh` }}></Grid>

        <Grid
          item
          align="left"
          xs={12}
          sx={{
            minHeight: "50vh",
            pointerEvents: "none",
          }}
        >
          <Typography sx={{ color: "white", fontSize: "2.5rem" }}>
            <Typewriter
              options={{
                cursor: "|",
                loop: false,
                delay: 60,
              }}
              onInit={(typewriter) => {
                typewriter
                  .pauseFor(2000) // Initial pause, slightly longer to grab attention
                  .typeString(
                    "> Welcome to my professional portal. I’m Aneesh Panoli, a seasoned full-stack data scientist and technology leader passionate about leveraging big data to build transformative software solutions. With a robust background in molecular genetics and machine learning, I thrive on turning complex scientific challenges into scalable and impactful technological applications."
                  )
                  .pauseFor(1500)
                  .callFunction((state) => {
                    state.elements.cursor.style.animation = "none";
                    state.elements.cursor.style.display = "none";
                    onCallSetMinHeight();
                    console.log("typewrite", state.elements);
                  })
                  .start();
              }}
            />
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
